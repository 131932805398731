<template>
  <div class="page-report">
    <div class="report-wrap">
      <span class="label">报告名称:</span>
      <el-input type="text" v-model="queryParams.name" placeholder="输入报告名称" class="input-box"/>
      <el-button type="primary" class="search-btn" @click="search">搜索</el-button>
    </div>
<!--    <div class="report-list">
      <div class="report-item" v-for="n in 10" :key="n">1.2017 ~ 2018纳税风险分析报告(2021-12-29 15:28:22) <i class="el-icon-delete"></i></div>
    </div>-->
<!--    <el-form :inline="true" :model="queryParams" class="demo-form-inline">
      <el-form-item label="报告名称:">
        <el-input v-model="queryParams.name" placeholder="输入报告名称" class="custom-width"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>-->
    <div class="report-list">
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column prop="title" label="报告名称" width="710" show-overflow-tooltip>
          <template slot-scope="scope"><el-link :href="`/v1/download/${scope.row.reportRecordUrl}`" target="_blank" >{{scope.row.name}}</el-link></template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" align="center">
          <template slot-scope="scope">{{moment(scope.row.createTime).format("YYYY-MM-DD hh:mm:ss")}}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row-page">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="pageChange"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myReport',
  data () {
    return {
      form: {
        name: ''
      },
      tableData: [],
      total: 0,
      queryParams: {
        limit: 10,
        page: 1,
        name: ''
      },
      loading: false
    }
  },
  methods: {
    search () {
      this.queryParams.page = 1
      this.getList()
    },
    pageChange (pageNum) {
      this.queryParams.page = pageNum
      this.getList()
    },
    getList () {
      this.loading = true
      this.API.searchReport(this.queryParams).then(({ data }) => {
        this.tableData = data.list
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.page-report{
  .report-wrap{
    display: flex;
    align-items: center;
    width: 800px;
    margin: 20px auto;
    .label{
      width:100px;
    }
    .input-box{
      flex: 1;
    }
    .search-btn{
      width: 100px;
      margin-left: 20px;
    }
  }
  .report-list{
    width: 900px;
    margin: 10px auto;
    text-align: left;
    .report-item{
      border-bottom: 1px dotted #999;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-icon-delete{
        cursor: pointer;
      }
    }
  }
}
</style>
